<template>
    <div>
        <Loading  v-if="!requested" />
        <div v-else>
            <span class="border__divider"></span>
            
            <div class="trending">
                <div class="trending-wrapper">
                    <div class="trending-main">
                        <div class="trending-header">
                            <a href="/">Home</a>
                            <v-icon color="black" size="24">
                                {{icons.mdiChevronRight}}
                            </v-icon>
                            <span>Trending</span>
                        </div>
                        <div class="trending-category">
                            <v-card rounded="0" v-for="food in trendingFoods" :key="food.id" class="trending-card-main" flat>
                                <div>
                                    <v-card class="trending-image-cont" width="90" rounded="lg" >
                                    <v-img :src="food.thumb" height="90"></v-img>
                                </v-card>
                                </div>

                                <div class="trending-text">
                                    <div class="trending-text-title">
                                        <h2>{{food.name}}</h2>
                                    </div>
                                    <div class="trending-text-desc">
                                        <span>Address</span>
                                        <span >Delivery, Pick-up</span>
                                    </div>
                                </div>
                                
                                
                            </v-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loading from "./Loading.vue"
import { mdiChevronRight } from '@mdi/js';
import imageNotFound from "@/assets/image-not-found.png";
import { getFoodsTrending} from '@/components/api/api.js'


export default {
name: "Restaurants",
components: {
    Loading,
},
data() {
    return {
        icons: {
            mdiChevronRight
        },
        imageNotFound: imageNotFound,
        trendingFoods: [],
        requested: false,
        Cuisines : [
        { title: 'Shrimp Cafe', isHalal: false,city: "Subang Jaya",
            image: "https://st2.depositphotos.com/1373322/5930/i/950/depositphotos_59302629-stock-photo-delicious-rice-noodles-with-shrimp.jpg"},
        { title: 'Chicken & Fill Cafe', isHalal: false,city: "Ampang",
            image: "https://st2.depositphotos.com/3147771/5842/i/950/depositphotos_58427629-stock-photo-fried-chicken-with-rice.jpg"},
        { title: 'Indian Shawarma Resturant', isHalal: true,city: "Bandar bukit Raja",
            image: "https://st3.depositphotos.com/2287637/18580/i/1600/depositphotos_185809996-stock-photo-chicken-tikka-masala-spicy-curry.jpg"},
        { title: 'Japan Teriyaki Restaurant', isHalal: false,city: "Bandar kinrara",
            image: "https://st2.depositphotos.com/1328914/7152/i/950/depositphotos_71527669-stock-photo-japanese-chicken-teriyaki-with-rice.jpg"},
        { title: 'Eat & Refill Cafe', isHalal: false,city: "Bandar Utama",
            image: "https://st2.depositphotos.com/4156511/9467/i/950/depositphotos_94673536-stock-photo-fried-rice-with-brussels-sprouts.jpg"},
        { title: 'Herb Soup Restaurant', isHalal: false,city: "Bukit Subang",
            image: "https://st.depositphotos.com/1025801/3506/i/950/depositphotos_35061335-stock-photo-chicken-and-herb-soup-in.jpg"},
        { title: 'Cafe Steam Bun', isHalal: false,city: "Batu caves",
            image: "https://st.depositphotos.com/1744806/2390/i/950/depositphotos_23900679-stock-photo-steamed-dumplings.jpg"},
        { title: 'Sushi Restaurant', isHalal: false,city: "Subang Jaya",
            image: "https://st2.depositphotos.com/1011976/9268/i/950/depositphotos_92683990-stock-photo-rolls-on-slate-table.jpg"},
        { title: 'Thai Restaurant', isHalal: true,city: "Bandar Jelutong",
            image: "https://st3.depositphotos.com/2822381/16824/i/1600/depositphotos_168244440-stock-photo-plate-of-traditional-thai-soup.jpg"},
        { title: 'Local Food Restaurant', isHalal: true,city: "Damansara",
            image: "https://st3.depositphotos.com/36196308/37696/i/1600/depositphotos_376965968-stock-photo-thai-spicy-salmon-soup-hot.jpg"},
        ]
    }
},
created() {
    this.getData()
},
methods: {
    getData(){
        var todayDate = new Date().toISOString().slice(0, 10);
        console.log(todayDate);

        let params = {
            category : 0,
            date : todayDate
        }
        getFoodsTrending(params)
            .then(res => {
                console.log('trend food =>',res.data)
                const indexpage = res.data
                this.trendingFoods = indexpage
                this.requested = true
            }).catch(error => {
                console.log('error: ',error)
            })
    },
},

}
</script>

<style lang='scss'>
@import "../components/common/css/global.scss";
@import "../components/common/css/common.scss";

.border__divider{
    height: 8px;
    background: $white5;
    width: 100%;
    display: block;
}

.trending {
    .trending-wrapper{
        padding: 0 14px;
    }
    .trending-main{
        padding: 20px 0;
    }
    .trending-header{
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        a{
            text-decoration: none;
            font-size: 24px;
            font-family:Arial, Helvetica, sans-serif;
            @media screen and (max-width: $bp-mobile){
                font-size: 20px;
                
            }
        }
        span{
            font-size: 18px;
            font-size: 24px;
            font-family: Arial, Helvetica, sans-serif;
            @media screen and (max-width: $bp-mobile){
                font-size: 20px;
            }
        }
    }

    .trending-category{
        .trending-card-main{
            display: flex;
            align-items: center;
            padding: 10px 0 10px 3px;
            margin: 0 0 5px;
            border-bottom: 1px solid $white3;

        }
        
        .trending-text{
            margin-left: 15px;
            height: 96px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-top: 5px;
                h2{
                display: block;
                line-height: 20px;
                height: 42px;
                overflow: hidden;
                font-size: 16px;
                font-weight: 600;
                color: $gray1;
            }
            .trending-text-desc{
                margin: 5px 0;
                display: flex;
                flex-direction: column;
                span:nth-child(1){
                    margin-bottom: 5px;
                    font-weight: 600;
                }
                span{
                    display: block;
                    overflow: hidden;
                    line-height: 20px;
                    font-weight: 400;
                    color: $gray2;
                    font-size: 12px;
                    font-family: Arial, Helvetica, sans-serif;
            }
            }
            
        }
    }
}


    
</style>